import Auth from "@/api/system/Auth";
import Users from "@/api/system/Users";
import Roles from "@/api/system/Roles";
import Reports from "@/api/system/Reports";
import Centers from "./system/Centers";
import Doctors from "@/api/system/Doctors";
import MedicalInstitutions from "@/api/system/MedicalInstitutions";
import Patients from "@/api/system/Patients";
import Equipment from "@/api/system/Equipment";
import ServiceCategories from "@/api/system/ServiceCategories";
import OnlineRequests from "@/api/system/OnlineRequests";
import Services from "@/api/system/Services";
import Appointments from "@/api/system/Appointments";
import OperationalTasks from "@/api/system/OperationalTasks";
import Notification from "@/api/system/Notification";
// @ts-ignore
import Employees from "@/api/system/Employees";
import WorkPositions from "@/api/system/WorkPositions";

export default {
  auth: () => new Auth(),
  appointments: () => new Appointments(),
  user: () => new Users(),
  notification: () => new Notification(),
  roles: () => new Roles(),
  reports: () => new Reports(),
  centers: () => new Centers(),
  doctors: () => new Doctors(),
  medical_institutions: () => new MedicalInstitutions(),
  patients: () => new Patients(),
  services: () => new Services(),
  service_categories: () => new ServiceCategories(),
  online_requests: () => new OnlineRequests(),
  equipment: () => new Equipment(),
  employees: () => new Employees(),
  workPositions: () => new WorkPositions(),
  users: () => new Users(),
  operational_tasks: () => new OperationalTasks()
};
