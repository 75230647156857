export default {
  light: {
    primary: "#1F2F93",
    secondary: "#262B33",
    "light-secondary": "#666666",
    light: "#EBEFF2",
    selected: "#F5F6F8",
    grey: "#FCFCFC",
    "light-gray": "#81848B",
    "lighter-gray": "#ECECEC",
    "expanded-row-header": "#23B7C9",
    "expanded-row": "#f8f8f8",
    text: "#444444",
    blue: "#46BCFF",
    darkBlue: "#00233C",
    purple: "#965BF7",
    error: "#F7685B",
    success: "#2ED47A",
    warning: "#FFB948",
    hover: "#393c41",
    "odd-row": "#f9f9f9",
    /* NavBar Colors */
    "nav-bar-color": "#ffffff",
    "nav-bar-icons": "#ffffff",
    /* SideBar Colors */
    "side-bar-text-color": "#81848B",
    "side-bar-header-color": "#666666",
    "side-bar-color": "#262B33",
    "side-bar-hovered-color": "#393c41",
    "side-bar-selected-text-color": "#F5F6F8",
    "side-bar-accent-color": "#1b1f24",
    "table-header-color": "#fcfcfc",
    "card-color": "#ffffff",
    "scroll-color": "#e0dfe1"
  },
  dark: {
    primary: "#1F2F93",
    secondary: "#262B33",
    "light-secondary": "#666666",
    light: "#EBEFF2",
    selected: "#F5F6F8",
    grey: "#1e1e1e",
    "light-gray": "#81848B",
    "lighter-gray": "#ECECEC",
    text: "#eaeaea",
    blue: "#46BCFF",
    darkBlue: "#00233C",
    purple: "#965BF7",
    error: "#F7685B",
    success: "#2ED47A",
    warning: "#FFB948",
    hover: "#393c41",
    "odd-row": "#f9f9f9",
    /* NavBar Colors */
    "nav-bar-color": "#1e1e1e",
    "nav-bar-icons": "#ffffff",
    /* SideBar Colors */
    "side-bar-text-color": "#81848B",
    "side-bar-header-color": "#666666",
    "side-bar-color": "#262B33",
    "side-bar-hovered-color": "#393c41",
    "side-bar-selected-text-color": "#F5F6F8",
    "side-bar-accent-color": "#1b1f24",
    "table-header-color": "#1e1e1e",
    "card-color": "#1e1e1e",
    "scroll-color": "#484848"
  }
};
