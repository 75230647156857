const meta = {
  module: "services"
};

export default [
  {
    path: `/services`,
    redirect: "/services/page/1"
  },
  {
    path: `/services/page`,
    redirect: "/services/page/1"
  },
  {
    path: `/services/page/:page`,
    name: "services",
    component: () =>
      import(
        /* webpackChunkName: "services" */ "@/modules/services/views/index.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/services/create`,
    name: "services/create",
    component: () =>
      import(
        /* webpackChunkName: "services/create" */ "@/modules/services/views/create.vue"
      ),
    meta: {
      ...meta,
      action: "create"
    }
  },
  {
    path: `/services/edit/:id`,
    name: "services/edit",
    component: () =>
      import(
        /* webpackChunkName: "services/edit" */ "@/modules/services/views/edit.vue"
      ),
    meta: {
      ...meta,
      action: "edit"
    }
  }
];
