
import Vue from "vue";
import AppLayout from "@/layouts/index.vue";
import PageLoader from "@/components/navigation/PageLoader.vue";

export default Vue.extend({
  name: "App",

  components: { PageLoader, AppLayout },

  computed: {
    showGlobalLoader(): boolean {
      return this.$store.getters["preloader/showGlobal"];
    }
  }
});
