import { AxiosResponse } from "axios";
import APIModel from "../APIModel";

export default class Auth extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "oauth";
  }

  async login(payload: object): Promise<AxiosResponse> {
    return await this.http.ignoreErrorHandler().post(`/login`, payload);
  }

  async changeSecurity(payload: any): Promise<AxiosResponse> {
    return await this.http
      .ignoreGlobalPreloader()
      .ignoreErrorHandler()
      .post(`profile/update_settings`, payload);
  }

  async forgot(payload: object): Promise<AxiosResponse> {
    return await this.http
      .ignoreGlobalPreloader()
      .post(`${this.resourceUrl}/login`, payload);
  }
}
