export default {
  namespaced: true,

  state: {
    expanded: !!JSON.parse(
      localStorage.getItem("fixedMenu") as string
    ) as boolean
  },

  mutations: {
    show(state: any) {
      state.expanded = true;
    },
    hide(state: any) {
      state.expanded = false;
    },
    set(state: any, value: boolean) {
      state.expanded = value;
    },
    toggle(state: any) {
      state.expanded = !state.expanded;
      localStorage.setItem("fixedMenu", state.expanded);
    }
  },

  actions: {
    show(context: any) {
      context.commit("show");
    },
    set(context: any, value: boolean) {
      context.commit("set", value);
    },
    hide(context: any) {
      context.commit("hide");
    },
    toggle(context: any) {
      context.commit("toggle");
    }
  },

  getters: {
    expanded: (state: any): boolean => state.expanded
  }
};
