const meta = {
  module: "users"
};

export default [
  {
    path: `/users`,
    redirect: "/users/page/1"
  },
  {
    path: `/users/page`,
    redirect: "/users/page/1"
  },
  {
    path: `/users/page/:page`,
    name: "users",
    component: () =>
      import(/* webpackChunkName: "users" */ "@/modules/users/views/index.vue"),
    meta: {
      ...meta
    }
  },
  {
    path: `/users/create`,
    name: "users/create",
    component: () =>
      import(
        /* webpackChunkName: "roles/edit" */ "@/modules/users/views/create.vue"
      ),
    meta: {
      ...meta,
      action: "create"
    }
  },
  {
    path: `/users/edit/:id`,
    name: "users/edit",
    component: () =>
      import(
        /* webpackChunkName: "roles/edit" */ "@/modules/users/views/edit.vue"
      ),
    meta: {
      ...meta,
      action: "create"
    }
  }
  // {
  //   path: `/users/centers/:center_id`,
  //   name: "users/centers",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "roles/edit" */ "@/modules/users/views/index.vue"
  //     ),
  //   meta: {
  //     ...meta,
  //     action: "listByFilter"
  //   }
  // }
];
