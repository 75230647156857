const meta = {
  module: "patients"
};

export default [
  {
    path: `/patients`,
    redirect: "/patients/page/1"
  },
  {
    path: `/patients/page`,
    redirect: "/patients/page/1"
  },
  {
    path: `/patients/page/:page`,
    name: "patients",
    component: () =>
      import(
        /* webpackChunkName: "patients" */ "@/modules/patients/views/index.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/patients/create`,
    name: "patients/create",
    component: () =>
      import(
        /* webpackChunkName: "patients/create" */ "@/modules/patients/views/create.vue"
      ),
    meta: {
      ...meta,
      action: "create"
    }
  },
  {
    path: `/patients/edit/:id`,
    name: "patients/edit",
    component: () =>
      import(
        /* webpackChunkName: "patients/edit" */ "@/modules/patients/views/edit.vue"
      ),
    meta: {
      ...meta,
      action: "edit"
    }
  }
];
