import APIModel from "../APIModel";

export default class OperationalTasks extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "operational_tasks";
  }

  async getData(params: object): Promise<any> {
    // return await new Promise<any>(resolve => {
    //   resolve(
    //     [...new Array(15)].map((item: any, index: number) => ({
    //       id: index,
    //       appointment_number: `${index}-H-123`,
    //       patient_name: "Vasya",
    //       patient_age: index * 13,
    //       doctor: "Test Name",
    //       services: [
    //         {
    //           status: "reserved",
    //           name: `IRM Test test ${index + 1}`,
    //           last_status_change: "12.12.2012",
    //           imagist: "Name Test",
    //           technician: "Nest Tame"
    //         },
    //         {
    //           status: "reserved",
    //           name: `IRM Test test ${index + 1}`,
    //           last_status_change: "12.12.2012",
    //           imagist: "Name Test",
    //           technician: "Nest Tame"
    //         },
    //         {
    //           status: "reserved",
    //           name: `IRM Test test ${index + 1}`,
    //           last_status_change: "12.12.2012",
    //           imagist: "Name Test",
    //           technician: "Nest Tame"
    //         },
    //         {
    //           status: "reserved",
    //           name: `IRM Test test ${index + 1}`,
    //           last_status_change: "12.12.2012",
    //           imagist: "Name Test",
    //           technician: "Nest Tame"
    //         }
    //       ]
    //     }))
    //   );
    // });
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}`, params);
  }

  async getForEdit(id: number): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/edit`);
  }

  async getList(params = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/statuses_list`, params);
  }

  async edit(id: number, payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`${this.resourceUrl}/${id}?_method=PATCH`, payload);
  }

  async create(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`${this.resourceUrl}`, payload);
  }

  async setImagist(id: number, imagist_id: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .patch(`appointment_services/${id}/imagist`, { imagist_id });
  }

  async setTechnician(id: number, technician_id: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .patch(`appointment_services/${id}/technician`, {
        technician_id
      });
  }

  async delete(id: any): Promise<any> {
    return await this.http.delete(`${this.resourceUrl}`, { id });
  }
}
