const meta = {
  module: "medical-institutions"
};

export default [
  {
    path: `/medical-institutions`,
    redirect: "/medical-institutions/page/1"
  },
  {
    path: `/medical-institutions/page`,
    redirect: "/medical-institutions/page/1"
  },
  {
    path: `/medical-institutions/page/:page`,
    name: "medical-institutions",
    component: () =>
      import(
        /* webpackChunkName: "medical-institutions" */ "@/modules/medical-institutions/views/index.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/medical-institutions/create`,
    name: "medical-institutions/create",
    component: () =>
      import(
        /* webpackChunkName: "medical-institutions/create" */ "@/modules/medical-institutions/views/create.vue"
      ),
    meta: {
      ...meta,
      action: "create"
    }
  },
  {
    path: `/medical-institutions/edit/:id`,
    name: "medical-institutions/edit",
    component: () =>
      import(
        /* webpackChunkName: "medical-institutions/edit" */ "@/modules/medical-institutions/views/edit.vue"
      ),
    meta: {
      ...meta,
      action: "edit"
    }
  }
];
