import APIModel from "../APIModel";

export default class Patients extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "patients";
  }

  async getData(params: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}`, params);
  }

  async getForEdit(id: number): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/edit`);
  }

  async liveSearch(
    query: string,
    text = "idnp",
    label = "name",
    resident = true
  ): Promise<any> {
    const params = {
      fields: {
        value: "id",
        text: text,
        label: label
      },
      filter: {
        [text]: query,
        resident: Number(resident)
      },
      route: "v1.patients.index.get"
    };
    // return await this.http.get(`${this.resourceUrl}/live_search?fields[value]=id&fields[text]=${text}&fields[label]=name&route=v1.patients.index.get&filter[idnp]=${query}`);
    return await this.http.get(`${this.resourceUrl}/live_search`, params);
  }
  async search(params: any = {}): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/search`, params);
  }
  async getLiveSearchList(query: any, id: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(
        `appointments/search/client?search_str=${query}&equipment_id=${id}`,
        {}
      );
  }

  async checkExistent(key: string, value: string): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .get(`${this.resourceUrl}/check_unique/${key}/${value}`, { value });
  }

  async getList(): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/list`, {});
  }

  async edit(id: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`${this.resourceUrl}/${id}?_method=PATCH`, payload);
  }

  async create(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`${this.resourceUrl}`, payload);
  }

  async update(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .patch(`${this.resourceUrl}/${payload.id}`, payload);
  }

  async delete(id: any): Promise<any> {
    return await this.http.delete(`${this.resourceUrl}`, { id });
  }
}
