const meta = {
  module: "appointments",
  withoutWrapperPadding: true
};

export default [
  {
    path: `/appointments/page/:page`,
    name: "appointments",
    component: () =>
      import(
        /* webpackChunkName: "appointments" */ "@/modules/appointments/views/index.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/appointments`,
    redirect: "/appointments/page/1"
  },
  {
    path: `/appointments/page`,
    redirect: "/appointments/page/1"
  },
  {
    path: `/appointments/client`,
    name: "appointments/client",
    component: () =>
      import(
        /* webpackChunkName: "appointments" */ "@/modules/appointments/views/create.vue"
      ),
    meta: {
      ...meta
    }
  }
];
