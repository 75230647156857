const meta = {
  module: "service-categories"
};

export default [
  {
    path: `/service-categories`,
    redirect: "/service-categories/page/1"
  },
  {
    path: `/service-categories/page`,
    redirect: "/service-categories/page/1"
  },
  {
    path: `/service-categories/page/:page`,
    name: "service-categories",
    component: () =>
      import(
        /* webpackChunkName: "service-categories" */ "@/modules/service-categories/views/index.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/service-categories/create`,
    name: "service-categories/create",
    component: () =>
      import(
        /* webpackChunkName: "service-categories/create" */ "@/modules/service-categories/views/create.vue"
      ),
    meta: {
      ...meta,
      action: "create"
    }
  },
  {
    path: `/service-categories/edit/:id`,
    name: "service-categories/edit",
    component: () =>
      import(
        /* webpackChunkName: "service-categories/edit" */ "@/modules/service-categories/views/edit.vue"
      ),
    meta: {
      ...meta,
      action: "edit"
    }
  }
];
