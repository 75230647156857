const meta = {
  module: "reports"
};

export default [
  {
    path: `/reports`,
    redirect: "/reports/dashboard"
  },
  {
    path: `/reports/dashboard`,
    name: "reports",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/modules/reports/views/index.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/reports/detailed`,
    name: "reports",
    component: () =>
      import(
        /* webpackChunkName: "detailed" */ "@/modules/reports/views/detailed.vue"
      ),
    meta: {
      ...meta
    }
  }
];
