import APIModel from "../APIModel";

export default class Appointments extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "appointments";
  }

  getTime(time: string) {
    return new Date(`2021-03-19T${time}:00.692Z`)
      .toISOString()
      .split("T")[1]
      .substr(0, 5);
  }

  getISOTime(time: string) {
    return Math.floor(new Date(`2021-03-19T${time}:00.692Z`).getTime() / 1000);
  }

  async createAppointment(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`${this.resourceUrl}`, payload);
  }

  async getForEdit(id: number): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/edit`);
  }

  async getStatusHistory(id: number): Promise<any> {
    return await this.http.get(`/appointment_services/status_history/${id}`);
  }

  async getPatientHistory(patient_id: number, id: number): Promise<any> {
    return await this.http.get(
      `patients/${patient_id}/appointment/${id}/except`
    );
  }

  async getPDF(payload: any): Promise<any> {
    return await this.http.ignoreErrorHandler().post(`questionnaires`, payload);
  }

  async updateServiceStatus(id: number, params: object): Promise<any> {
    return await this.http.patch(
      `appointment_services/${id}/change_status`,
      params
    );
  }

  async removeServiceStatus(id: number, params: object): Promise<any> {
    return await this.http.patch(
      `appointment_services/${id}/cancel_status`,
      params
    );
  }

  async getData(params: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .ignoreGlobalPreloader()
      .get(`${this.resourceUrl}`, params);
    // return new Promise(resolve => {
    //   resolve(
    //     [...new Array(10)].map((_, index) => ({
    //       date: "15.03.2021",
    //       created_at: this.getISOTime("06:00"),
    //       end_time: this.getISOTime("22:00"),
    //       id: index,
    //       totals: [
    //         {
    //           alias: "reserved",
    //           value: 7
    //         },
    //         {
    //           alias: "planned",
    //           value: 1
    //         },
    //         {
    //           alias: "present",
    //           value: 3
    //         },
    //         {
    //           alias: "service",
    //           value: 4
    //         },
    //         {
    //           alias: "description",
    //           value: 8
    //         },
    //         {
    //           alias: "canceled",
    //           value: 6
    //         }
    //       ].slice(0, +(Math.random() * 10).toFixed()),
    //       price: (999.99 * index).toFixed(2)
    //     }))
    //   );
    // });
  }

  async update(id: number, payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`${this.resourceUrl}/${id}?_method=PATCH`, payload);
  }

  async create(appointment_service_id: number, text: string): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`appointment_service_descriptions`, {
        appointment_service_id,
        text
      });
  }

  async getPdfLink(id: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .get(`appointment_services/${id}/investigation_report_demo`, { id });
  }

  async savePreview(id: number, preview: string): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .patch(`appointment_service_descriptions/${id}/save_preview`, {
        preview
      });
  }

  async finish(id: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .get(`appointment_service_descriptions/${id}/save`, { id });
  }

  async edit(id: number, text: string): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .patch(`appointment_service_descriptions/${id}`, { text });
  }

  async getReport(id: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`appointment_service_descriptions/${id}/edit`, { id });
  }

  async getAvailableSegments(params: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/get_available_segments`, params);
  }

  async move(id: number, payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .patch(`${this.resourceUrl}/${id}/move`, payload);
  }

  async getStatuses(): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .ignoreGlobalPreloader()
      .get(`appointment_service_statuses/list`, {});
  }
  async getRow(params: object): Promise<any> {
    // const index = 1;
    // const id = 4;
    return await this.http
      .ignoreErrorHandler()
      .ignoreGlobalPreloader()
      .get(`${this.resourceUrl}/details`, params);
    // return new Promise(resolve => {
    //   resolve([
    //     {
    //       id: index.toString().padStart(5, "0"),
    //       patient: {
    //         name: "Comanarnitchi Veceslav Iurievici",
    //         phone: "+373345345",
    //         sex: "M",
    //         year: id.toString().padStart(4, "20"),
    //         age: index * 10,
    //         address: "Moldova"
    //       },
    //       doctor: {
    //         name: "Comanarnitchi Veceslav Iurievici"
    //       },
    //       start_time: this.getISOTime("09:00"),
    //       end_time: this.getISOTime("09:30"),
    //       services: [
    //         {
    //           start_time: this.getISOTime("09:00"),
    //           end_time: this.getISOTime("09:30"),
    //           status: "reserved",
    //           code: id.toString().padStart(5, "2"),
    //           name: "Rezonanţa magnetică",
    //           price: 1234.56,
    //           payment_type: "certificat",
    //           document_number: "32432a"
    //         }
    //       ]
    //     },
    //     {
    //       id: index.toString().padStart(5, "0"),
    //       patient: {
    //         name: "Comanarnitchi Veceslav Iurievici",
    //         phone: "+373345345",
    //         sex: "M",
    //         year: id.toString().padStart(4, "20"),
    //         age: index * 10,
    //         address: "Moldova"
    //       },
    //       doctor: {
    //         name: "Comanarnitchi Veceslav Iurievici"
    //       },
    //       start_time: this.getISOTime("09:30"),
    //       end_time: this.getISOTime("10:30"),
    //       services: [
    //         {
    //           start_time: this.getISOTime("09:30"),
    //           end_time: this.getISOTime("10:30"),
    //           status: "reserves",
    //           code: id.toString().padStart(5, "2"),
    //           name: "Rezonanţa magnetică",
    //           price: 1234.56,
    //           payment_type: "certificat",
    //           document_number: "32432a"
    //         }
    //       ]
    //     },
    //     {
    //       id: index.toString().padStart(5, "0"),
    //       patient: {
    //         name: "Comanarnitchi Veceslav Iurievici",
    //         phone: "+373345345",
    //         sex: "M",
    //         year: id.toString().padStart(4, "20"),
    //         age: index * 10,
    //         address: "Moldova"
    //       },
    //       doctor: {
    //         name: "Comanarnitchi Veceslav Iurievici"
    //       },
    //       start_time: this.getISOTime("11:00"),
    //       end_time: this.getISOTime("11:30"),
    //       services: [
    //         {
    //           start_time: this.getISOTime("11:00"),
    //           end_time: this.getISOTime("11:30"),
    //           status: "planned",
    //           code: id.toString().padStart(5, "2"),
    //           name: "Rezonanţa magnetică",
    //           price: 1234.56,
    //           payment_type: "certificat",
    //           document_number: "32432a"
    //         }
    //       ]
    //     },
    //     {
    //       id: index.toString().padStart(5, "0"),
    //       patient: {
    //         name: "Comanarnitchi Veceslav Iurievici",
    //         phone: "+373345345",
    //         sex: "M",
    //         year: id.toString().padStart(4, "20"),
    //         age: index * 10,
    //         address: "Moldova"
    //       },
    //       doctor: {
    //         name: "Comanarnitchi Veceslav Iurievici"
    //       },
    //       start_time: this.getISOTime("12:00"),
    //       end_time: this.getISOTime("12:30"),
    //       services: [
    //         {
    //           start_time: this.getISOTime("12:00"),
    //           end_time: this.getISOTime("12:30"),
    //           status: "present",
    //           code: id.toString().padStart(5, "2"),
    //           name: "Rezonanţa magnetică",
    //           price: 1234.56,
    //           payment_type: "certificat",
    //           document_number: "32432a"
    //         }
    //       ]
    //     },
    //     {
    //       id: index.toString().padStart(5, "0"),
    //       patient: {
    //         name: "Comanarnitchi Veceslav Iurievici",
    //         phone: "+373345345",
    //         sex: "M",
    //         year: id.toString().padStart(4, "20"),
    //         age: index * 10,
    //         address: "Moldova"
    //       },
    //       doctor: {
    //         name: "Comanarnitchi Veceslav Iurievici"
    //       },
    //       start_time: this.getISOTime("12:30"),
    //       end_time: this.getISOTime("13:00"),
    //       services: [
    //         {
    //           start_time: this.getISOTime("12:30"),
    //           end_time: this.getISOTime("13:00"),
    //           status: "canceled",
    //           code: id.toString().padStart(5, "2"),
    //           name: "Rezonanţa magnetică",
    //           price: 1234.56,
    //           payment_type: "certificat",
    //           document_number: "32432a"
    //         }
    //       ]
    //     },
    //     {
    //       id: index.toString().padStart(5, "0"),
    //       patient: {
    //         name: "Comanarnitchi Veceslav Iurievici",
    //         phone: "+373345345",
    //         sex: "M",
    //         year: id.toString().padStart(4, "20"),
    //         age: index * 10,
    //         address: "Moldova"
    //       },
    //       doctor: {
    //         name: "Comanarnitchi Veceslav Iurievici"
    //       },
    //       start_time: this.getISOTime("13:00"),
    //       end_time: this.getISOTime("14:30"),
    //       services: [
    //         {
    //           start_time: this.getISOTime("13:00"),
    //           end_time: this.getISOTime("14:30"),
    //           status: "service",
    //           code: id.toString().padStart(5, "2"),
    //           name: "Rezonanţa magnetică",
    //           price: 1234.56,
    //           payment_type: "certificat",
    //           document_number: "32432a"
    //         }
    //       ]
    //     },
    //     {
    //       id: index.toString().padStart(5, "0"),
    //       patient: {
    //         name: "Comanarnitchi Veceslav Iurievici",
    //         phone: "+373345345",
    //         sex: "M",
    //         year: id.toString().padStart(4, "20"),
    //         age: index * 10,
    //         address: "Moldova"
    //       },
    //       doctor: {
    //         name: "Comanarnitchi Veceslav Iurievici"
    //       },
    //       start_time: this.getISOTime("15:00"),
    //       end_time: this.getISOTime("15:30"),
    //       services: [
    //         {
    //           start_time: this.getISOTime("15:00"),
    //           end_time: this.getISOTime("15:30"),
    //           status: "executed",
    //           code: id.toString().padStart(5, "2"),
    //           name: "Rezonanţa magnetică",
    //           price: 1234.56,
    //           payment_type: "certificat",
    //           document_number: "32432a"
    //         }
    //       ]
    //     },
    //     {
    //       id: index.toString().padStart(5, "0"),
    //       patient: {
    //         name: "Comanarnitchi Veceslav Iurievici",
    //         phone: "+373345345",
    //         sex: "M",
    //         year: id.toString().padStart(4, "20"),
    //         age: index * 10,
    //         address: "Moldova"
    //       },
    //       doctor: {
    //         name: "Comanarnitchi Veceslav Iurievici"
    //       },
    //       start_time: this.getISOTime("15:30"),
    //       end_time: this.getISOTime("16:00"),
    //       services: [
    //         {
    //           start_time: this.getISOTime("15:30"),
    //           end_time: this.getISOTime("16:00"),
    //           status: "description",
    //           code: id.toString().padStart(5, "2"),
    //           name: "Rezonanţa magnetică",
    //           price: 1234.56,
    //           payment_type: "certificat",
    //           document_number: "32432a"
    //         }
    //       ]
    //     },
    //     {
    //       id: index.toString().padStart(5, "0"),
    //       patient: {
    //         name: "Comanarnitchi Veceslav Iurievici",
    //         phone: "+37361072894, +37361072896",
    //         sex: "M",
    //         year: id.toString().padStart(4, "20"),
    //         age: index * 10,
    //         address: "Moldova, mun. Balti, str. Alexandru cel Bun 1-a"
    //       },
    //       doctor: {
    //         name: "Comanarnitchi Veceslav Iurievici"
    //       },
    //       start_time: this.getISOTime("17:30"),
    //       end_time: this.getISOTime("18:00"),
    //       services: [
    //         {
    //           start_time: this.getISOTime("17:30"),
    //           end_time: this.getISOTime("18:00"),
    //           status: "rendition",
    //           code: id.toString().padStart(5, "2"),
    //           name: "Rezonanţa magnetică",
    //           price: 1234.56,
    //           payment_type: "certificat",
    //           document_number: "32432a"
    //         }
    //       ]
    //     },
    //     {
    //       id: index.toString().padStart(5, "0"),
    //       patient: {
    //         name: "Comanarnitchi Veceslav Iurievici",
    //         phone: "+373345345",
    //         sex: "M",
    //         year: id.toString().padStart(4, "20"),
    //         age: index * 10,
    //         address: "Moldova"
    //       },
    //       doctor: {
    //         name: "Comanarnitchi Veceslav Iurievici"
    //       },
    //       start_time: this.getISOTime("18:30"),
    //       end_time: this.getISOTime("19:00"),
    //       services: [
    //         {
    //           start_time: this.getISOTime("18:30"),
    //           end_time: this.getISOTime("19:00"),
    //           status: "completed",
    //           code: id.toString().padStart(5, "2"),
    //           name: "Rezonanţa magnetică",
    //           price: 1234.56,
    //           payment_type: "certificat",
    //           document_number: "32432a"
    //         }
    //       ]
    //     },
    //     {
    //       id: index.toString().padStart(5, "0"),
    //       patient: {
    //         name: "Comanarnitchi Veceslav Iurievici",
    //         phone: "+373345345",
    //         sex: "M",
    //         year: id.toString().padStart(4, "20"),
    //         age: index * 10,
    //         address: "Moldova"
    //       },
    //       doctor: {
    //         name: "Comanarnitchi Veceslav Iurievici"
    //       },
    //       start_time: this.getISOTime("19:30"),
    //       end_time: this.getISOTime("20:00"),
    //       services: [
    //         {
    //           start_time: this.getISOTime("19:30"),
    //           end_time: this.getISOTime("20:00"),
    //           status: "maintenance",
    //           code: id.toString().padStart(5, "2"),
    //           name: "Rezonanţa magnetică",
    //           price: 1234.56,
    //           payment_type: "certificat",
    //           document_number: "32432a"
    //         }
    //       ]
    //     }
    //   ]);
    // });
    // return await this.http
    //   .ignoreErrorHandler(500)
    //   .ignoreGlobalPreloader()
    //   .get(`${this.resourceUrl}`, params);
  }
}
