export default {
  operator: [
    {
      action: ["request"],
      subject: ["NavBar:actions"]
    }
  ],
  admin: [
    {
      action: ["request"],
      subject: ["NavBar:actions"]
    }
  ],
  imagist: [
    {
      action: [],
      subject: ["NavBar:actions"]
    }
  ],
  investor: [
    {
      action: [],
      subject: ["NavBar:actions"]
    }
  ],
  imagist_chief: [
    {
      action: [],
      subject: ["NavBar:actions"]
    }
  ],
  technician: [
    {
      action: ["request"],
      subject: ["NavBar:actions"]
    }
  ]
};
