import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import middleware from "./middleware";

import authentication from "@/router/modules/authentication";
import appointments from "@/router/modules/appointments";
import errors from "@/router/modules/errors";
// import general from "@/router/modules/general";
import centers from "@/router/modules/centers";
import doctors from "@/router/modules/doctors";
import medical_institutions from "@/router/modules/medical-institutions";
import service_categories from "@/router/modules/service-categories";
import online_requests from "@/router/modules/online-requests";
import patients from "@/router/modules/patients";
import services from "@/router/modules/services";
import equipment from "@/router/modules/equipment";
import roles from "@/router/modules/roles";
// @ts-ignore
import reports from "@/router/modules/reports";
import users from "@/router/modules/users";
import store from "@/store";
// @ts-ignore
import employees from "@/router/modules/employees";
// @ts-ignore
import work_positions from "@/router/modules/work-positions";
import operational_tasks from "@/router/modules/operational-tasks";

const routes: Array<RouteConfig> = [
  ...appointments,
  ...errors,
  // ...general,
  ...authentication,
  ...service_categories,
  ...online_requests,
  ...centers,
  ...roles,
  ...reports,
  ...doctors,
  ...medical_institutions,
  ...patients,
  ...services,
  ...equipment,
  ...users,
  ...employees,
  ...work_positions,
  ...operational_tasks
];

Vue.use(VueRouter);

store.dispatch("localization/setFromRouter");

const router = new VueRouter({
  mode: "history",
  base: store.getters["localization/getCurrent"],
  routes
});

middleware.apply(router);

export default router;
