import APIModel from "../APIModel";

export default class Doctors extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "doctors";
  }

  async getData(params: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}`, params);
  }

  async getForEdit(id: number): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/edit`);
  }

  async getList(): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/list`, {});
  }

  async getDoctorsAppointments(id: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`appointments/search/doctor/${id}`, {});
  }

  async liveSearch(query: any, text = "email", label = "name"): Promise<any> {
    const params = {
      fields: {
        value: "id",
        text: text,
        label: label
      },
      filter: {
        [text]: query
      },
      route: "v1.doctors.index.get"
    };
    return await this.http.get(`${this.resourceUrl}/live_search`, params);
    // return await this.http.get(
    //   `${this.resourceUrl}/live_search?fields[value]=id&fields[text]=email&fields[label]=name&route=v1.doctors.index.get&filter[email]=${query}`
  }

  async liveSearchDoctors(query: string): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .get(`appointments/live_search/doctor?searchValue=${query}`, {});
  }

  async getDoctorInfo(id: number): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/show`);
  }

  async search(params: any = {}): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/search`, params);
  }

  async searchWorkPlace(query: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler()
      .get(
        `${this.resourceUrl}/live_search?fields[value]=id&fields[text]=medical_institutions&route=v1.doctors.index.get&filter[medical_institutions]=${query}`,
        query
      );
  }

  async edit(id: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`${this.resourceUrl}/${id}?_method=PATCH`, payload);
  }

  async create(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`${this.resourceUrl}`, payload);
  }

  async delete(id: any): Promise<any> {
    return await this.http.delete(`${this.resourceUrl}`, { id });
  }
}
