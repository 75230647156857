import APIModel from "../APIModel";

export default class MedicalInstitutions extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "medical_institutions";
  }

  async getData(params: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}`, params);
  }

  async getForEdit(id: number): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/edit`);
  }

  async getList(): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/list`, {});
  }

  async liveSearchEmail(query: any): Promise<any> {
    return await this.http.get(
      `${this.resourceUrl}/live_search?fields[value]=id&fields[text]=email&route=v1.doctors.index.get&filter[email]=${query}`
    );
  }

  async liveSearch(query: any): Promise<any> {
    return await this.http.get(
      `${this.resourceUrl}/live_search?fields[value]=id&fields[text]=name&route=v1.medical_institutions.index.get&filter[name]=${query}`
    );
  }

  async getDoctorInfo(id: number): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/show/${id}`);
  }

  async searchWorkPlace(query: any): Promise<any> {
    return await this.http.get(
      `${this.resourceUrl}/live_search?fields[value]=id&fields[text]=work_place&route=v1.doctors.index.get&filter[work_place]=${query}`
    );
  }

  async edit(id: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .post(`${this.resourceUrl}/${id}?_method=PATCH`, payload);
  }

  async create(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`${this.resourceUrl}`, payload);
  }

  async delete(id: any): Promise<any> {
    return await this.http.delete(`${this.resourceUrl}`, { id });
  }
}
