import VueRouter, { NavigationGuardNext, Route } from "vue-router";
import store from "@/store";
import ability from "@/plugins/ability";
import { mapGetters } from "vuex";
import login from "@/modules/auth/views/login.vue";

const loadUserPermissions = async (to: Route) => {
  try {
    const { user } = store.getters["authentication/credentials"];
    const modulePermissions = await import(
      `@/modules/${to.meta.module}/config/permissions.ts`
    );

    const globalPermissions = require.context(
      "@/permissions",
      true,
      /[A-Za-z0-9-_,\s]+\.ts$/i
    );

    const permissions = [...modulePermissions.default[user.position]];

    for (const path of globalPermissions.keys()) {
      const permission = await import(`@/permissions/${path.substring(2)}`);

      permissions.push(...permissions, ...permission.default[user.position]);
    }

    ability.update(permissions);
  } catch {
    await Promise.resolve();
  }
};

const apply = (router: VueRouter) => {
  router.beforeEach(
    async (to: Route, from: Route, next: NavigationGuardNext) => {
      const isAuthorized = store.getters["authentication/hasAccessToken"];

      if (!to.meta.withoutCredentials && !isAuthorized) {
        next(`/auth/login`);
        return;
      }

      try {
        const { user } = store.getters["authentication/credentials"];
        const permissions = await import(
          `@/modules/${to.meta.module}/config/permissions.ts`
        );

        ability.update(permissions.default[user.position]);
      } catch {
        await Promise.resolve();
      }

      await loadUserPermissions(to);

      next();
    }
  );

  router.afterEach((to: Route, from: Route) => {
    store.dispatch("preloader/hideGlobal");

    if (to.meta.errorPage) {
      history.replaceState(
        {},
        document.title,
        `/${store.getters["localization/getCurrent"]}${to.redirectedFrom ||
          from.path}`
      );
    }
  });
};

export default {
  apply
};
