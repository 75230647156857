const meta = {
  module: "doctors"
};

export default [
  {
    path: `/doctors`,
    redirect: "/doctors/page/1"
  },
  {
    path: `/doctors/page`,
    redirect: "/doctors/page/1"
  },
  {
    path: `/doctors/page/:page`,
    name: "doctors",
    component: () =>
      import(
        /* webpackChunkName: "doctors" */ "@/modules/doctors/views/index.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/doctors/create`,
    name: "doctors/create",
    component: () =>
      import(
        /* webpackChunkName: "doctors/create" */ "@/modules/doctors/views/create.vue"
      ),
    meta: {
      ...meta,
      action: "create"
    }
  },
  {
    path: `/doctors/edit/:id`,
    name: "doctors/edit",
    component: () =>
      import(
        /* webpackChunkName: "doctors/edit" */ "@/modules/doctors/views/edit.vue"
      ),
    meta: {
      ...meta,
      action: "edit"
    }
  }
];
