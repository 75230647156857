import Vue from "vue";
import Vuex from "vuex";
import localization from "./modules/localization";
import alert from "./modules/alert";
import preloader from "./modules/preloader";
import authentication from "./modules/authentication";
import navigation from "./modules/navigation";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    localization,
    alert,
    authentication,
    navigation,
    preloader
  },
  strict: process.env.NODE_ENV !== "production"
});
