const meta = {
  module: "centers"
};

export default [
  {
    path: `/centers`,
    redirect: "/centers/page/1"
  },
  {
    path: `/centers/page`,
    redirect: "/centers/page/1"
  },
  {
    path: `/centers/page/:page`,
    name: "centers",
    component: () =>
      import(
        /* webpackChunkName: "centers" */ "@/modules/centers/views/index.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/centers/create`,
    name: "centers/create",
    component: () =>
      import(
        /* webpackChunkName: "centers/create" */ "@/modules/centers/views/create.vue"
      ),
    meta: {
      ...meta,
      action: "create"
    }
  },
  {
    path: `/centers/edit/:id`,
    name: "centers/edit",
    component: () =>
      import(
        /* webpackChunkName: "centers/edit" */ "@/modules/centers/views/edit.vue"
      ),
    meta: {
      ...meta,
      action: "edit"
    }
  }
];
